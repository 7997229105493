import { SingleRecordState } from "../../models"
import { AppState } from "../../../../store/models"
import { Record, RecordSummaryType } from "../../../records/models"

// Constants
import { StoreKeys } from "../../../../store/constants"

// Selectors
import { getRecordsStateSlice } from "../../../records/store/selectors"
import { selectActiveRecordId } from "../../../single-patient/store/selectors"

// Helpers
import detailsFormatter from "../../helpers/detailsFormatter"
import { getDefaultOpened } from "../../helpers"

/**
 * @param state
 */
export const getSingleRecordStateSlice = (state: AppState): SingleRecordState => state[StoreKeys.SINGLE_RECORD];

/**
 * @param state
 */
export const selectSummaryFetching = (state: AppState) => getSingleRecordStateSlice(state).summaryFetching;

/**
 * @param state
 */
export const selectRawSignalsFetching = (state: AppState) => getSingleRecordStateSlice(state).rawSignalsFetching;

/**
 * @param state
 */
export const selectRawFileFetching = (state: AppState) => getSingleRecordStateSlice(state).rawFileFetching;

/**
 * @param state
 */
export const selectRawFile = (state: AppState) => getSingleRecordStateSlice(state).rawFile;

/**
 * @param state
 */
export const selectExcelFileFetching = (state: AppState) => getSingleRecordStateSlice(state).excelFileFetching;

/**
 * @param state
 */
export const selectExcelFile = (state: AppState) => getSingleRecordStateSlice(state).excelFile;

/**
 * @param state
 */
export const selectPdfFileFetching = (state: AppState) => getSingleRecordStateSlice(state).pdfFileFetching;

/**
 * @param state
 */
export const selectPdfFile = (state: AppState) => getSingleRecordStateSlice(state).pdfFile;

/**
 * @param state
 */
export const selectMarkingAsReviewed = (state: AppState) => getSingleRecordStateSlice(state).markingAsReviewed;

/**
 * @param state
 */
export const selectActiveLead = (state: AppState) => getSingleRecordStateSlice(state).selectedLead;

/**
 * @param state
 * @param id
 */
export const selectRecord = (state: AppState, id: Record["id"]) => {
    const { recordsById } = getRecordsStateSlice(state);
    const selectedRecord = recordsById[id];

    if (typeof selectedRecord === "undefined") {
        return null
    }

    return selectedRecord as RecordSummaryType // TODO: fix
};

/**
 * @param state
 * @param id
 */
export const selectHasRawSignals = (state: AppState, id: Record["id"]) => {
    const record = selectRecord(state, id);

    if (record === null) return false

    return Array.isArray(record.rawSignals)
};

/**
 * @param state
 */
export const selectQRSSignalsByLead = (state: AppState) => {
    const activeRecordId = selectActiveRecordId(state);
    const activeRecord = selectRecord(state, activeRecordId);

    if (activeRecord === null) {
        return null
    }

    return activeRecord.signals
};

/**
 * @param state
 */
export const selectRawSignalForActiveLead = (state: AppState) => {
    const activeLead = selectActiveLead(state);
    const activeRecordId = selectActiveRecordId(state);
    const activeRecord = selectRecord(state, activeRecordId);

    if (activeRecord === null) {
        return null
    }

    if (typeof activeRecord.rawSignals === "undefined") {
        return null
    }

    const selectedSignal = activeRecord.rawSignals.find(s => s.lead === activeLead);

    if (typeof selectedSignal === "undefined") {
        return null
    }

    return selectedSignal
};

export const selectDetailsStatus = (state: AppState) => ({
    detailsFetching: getSingleRecordStateSlice(state).detailsFetching,
    isDetailsVisible: getSingleRecordStateSlice(state).isDetailsVisible,
})

export const selectActiveRecordDetails = (state: AppState) => {
    const activeRecordId = selectActiveRecordId(state);
    const activeRecord = selectRecord(state, activeRecordId);

    if (activeRecord === null || !activeRecord.details || !activeRecord.details.values) {
        return {
            details: null,
            defaultOpened: null
        }
    }

    const preparedDetails = detailsFormatter(activeRecord.details.values)

    return {
        details: preparedDetails,
        defaultOpened: getDefaultOpened(preparedDetails),
    }
}