// Models
import {AppState} from "../../../../store/models"
import {EntityByIdMap} from "../../../../models"
import {GroupedPatients, Patient, PatientsStateInterface} from "../../models"

// Constants
import {StoreKeys} from "../../../../store/constants"

// Selectors
import {getGroupsStateSlice, selectGroupsMap} from "../../../groups/store/selectors"

/**
 * @param state
 */
export const getPatientsStateSlice = (state: AppState): PatientsStateInterface => state[StoreKeys.PATIENTS];

export const selectPatientsMap = (state: AppState): EntityByIdMap<Patient> => getPatientsStateSlice(state).patientsById

/**
 * @param state
 */
export const selectPatientsList = (state: AppState) => {
    const {patientsIds, patientsById} = getPatientsStateSlice(state);
    const {groupsById} = getGroupsStateSlice(state);

    return patientsIds.map(id => {
        return {
            ...patientsById[id],
            groups: patientsById[id].groups.map(id => groupsById[id]),
        }
    })
};

export const selectGroupedPatients = (state: AppState): GroupedPatients => {
    const patients = selectPatientsList(state);

    return patients.reduce((curr, next) => {
        const [firstLetter] = next.firstname || '';

        if (typeof firstLetter === "undefined") {
            return curr
        }

        if (typeof curr[firstLetter.toLowerCase()] === "undefined") {
            curr[firstLetter.toLowerCase()] = [next];

            return curr
        }

        return {
            ...curr,
            [firstLetter.toLowerCase()]: [
                ...curr[firstLetter.toLowerCase()],
                next,
            ],
        }
    }, {});
};

export const selectPatientsPagination = (state: AppState) => {
    const {
        totalPages,
        currentPage,
        previousPage,
        totalElements,
        patientsFetching,
        morePatientsFetching,
    } = getPatientsStateSlice(state);

    return {
        totalPages,
        currentPage,
        previousPage,
        totalElements,
        patientsFetching,
        morePatientsFetching,
    }
};

export const selectPatientsSearchParams = (state: AppState) => {
    const {
        sort,
        size,
        query,
        currentPage,
    } = getPatientsStateSlice(state);

    return {
        sort,
        size,
        query,
        page: currentPage,
    }
};
export const selectPatientsSearchQuery = (state: AppState) => getPatientsStateSlice(state).query;
export const selectPatientsBeingQueried = (state: AppState) => getPatientsStateSlice(state).querying;

export const selectPatientsFilterParams = (state: AppState) => {
    const {
        sex,
        ageTo,
        ageFrom,
        groupIds,
    } = getPatientsStateSlice(state);

    return {
        sex,
        ageTo,
        ageFrom,
        groupIds,
    }
};


export const selectDoctorStats = (state: AppState) => {
    const {doctorStats, doctorStatsFetching} = getPatientsStateSlice(state)

    return {
        doctorStats,
        doctorStatsFetching,
    }
}

export const selectRecentFetching = (state: AppState) => getPatientsStateSlice(state).recentFetching;

export const selectRecentPatientsList = (state: AppState) => {
    const {recentPatientsIds, patientsById} = getPatientsStateSlice(state);
    const {groupsById} = getGroupsStateSlice(state);

    return recentPatientsIds.map(id => {
        return {
            ...patientsById[id],
            groups: patientsById[id].groups.map(id => groupsById[id]),
        }
    })
};

export const selectPatientById = (state: AppState, id: string) => {
    const {patientsById} = getPatientsStateSlice(state);
    const groupsById = selectGroupsMap(state);
    let selectedPatient = patientsById[id];

    if (typeof selectedPatient === "undefined") {
        return null
    }

    if (typeof selectedPatient.groups !== "undefined") {
        selectedPatient = {
            ...selectedPatient,
            // @ts-ignore
            groups: selectedPatient.groups.map(id => groupsById[id]),
        };
    } else {
        selectedPatient = {
            ...selectedPatient,
            groups: [],
        };
    }

    return selectedPatient
};

export const selectIsInviteModalVisible = (state: AppState) => getPatientsStateSlice(state).inviteModalVisible;
export const selectInviteCode = (state: AppState) => getPatientsStateSlice(state).inviteCode;
export const selectInviteCodeGeneration = (state: AppState) => getPatientsStateSlice(state).inviteCodeGenerating;
