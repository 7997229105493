import {EntityByIdMap} from "../../../models"
import {Patient} from "../../patients/models"

export interface RecordsStateInterface {
    recordsById: EntityByIdMap<Record>
    recordsIds: Array<Record["id"]>
    recordsFetching: boolean
    moreFetching: boolean
    total: number
    totalPages: number
    // Search
    sort: string
    size: number
    currentPage: number
}

export enum RecordType {
    ECG = "ECG",
    HRV = "HRV",
}

export enum ChartType {
    FOURIER = "FOURIER",
    HISTOGRAM = "HISTOGRAM",
    AR_SPECTRE = "AR_SPECTRE",
    TRIANGULAR_HISTOGRAM = "TRIANGULAR_HISTOGRAM",
}

export enum ErrorCodeString {
    OK = "OK",
    SHORT_OR_NOISY = "SHORT_OR_NOISY",
    INTERNAL_ERROR = "INTERNAL_ERROR",
}

export type HistogramPoints = {
    x: number
    y: number
}

export type HistogramChartData = {
    chartType: ChartType,
    points: Array<HistogramPoints>
}

export type DetailsValue = {
    lead: string | null
    normalizedValue: number
    parameterName: string
    value: number
}

export type PreparedDetails = Array<{
    title: string
    children: PreparedDetails
    value?: {
        value: string | number
        unit?: string
        color?: string
    }
}>

export type SingleRecordDetails = {
    duration: null | number
    errorCode: number
    errorCodeString: ErrorCodeString
    recordId: string
    time: string
    type: RecordType
    values: Array<DetailsValue> | null
}

export type RecordSummaryMain = {
    bpm: number
    emotional: number
    arrhythmia: number
    energy: number
    stamina: number
    myocardium: number
    heartRisk: number
    ageFrom: number
    ageTo: number
    stress: number
    autonomicBalance: number
    qtInt: number
    qtcInt: number
    qtcFInt: number
    qrsDuration: number
    qrsAxis: number
    prInt: number
    confidenceIndex: number
}

export type RecordSignalType = {
    sampleRate: number
    units: string
    lead: string
    data: Array<number>
    signalQualityIndex: number
}

export type RecordECGParametersType = {
    lead: string
    prInterval: number
    qrsInterval: number
    qt: number
    j20: number
    j40: number
    j60: number
    j80: number
    jpoint: number
    ipoint: number
}

export type RecordHRVParametersType = {
    averageMrr: number
    modeNn: number
    sdnn: number
    sdarr: number
    cv: number
    averageHr: number
    minHr: number
    maxHr: number
    sdsd: number
    rmssd: number
    pnn50: number
    iars: number
    stressIndex: number
}

export type RecordAmplitudeType = {
    lead: string
    p: number
    q: number
    r: number
    s: number
    t: number
    j: number
    j20: number
    j40: number
    j60: number
    j80: number
}

export type RhythmEventsType = {
    start: number,
    end: number,
    name: string,
    heartRate?: number,
    type: string,
}

export type FrequencyType = {
    powerMs: string
    powerPercent: unknown
    powerNu: unknown
}

export type RecordFrequencyType = {
    total: FrequencyType
    vlf: FrequencyType
    lf: FrequencyType
    hf: FrequencyType
    lfhf: FrequencyType
    vlfhf: FrequencyType
    ic: FrequencyType
}

export type MinnesotaCodeType = {
    id: number
    name: string
    abnormality: string
    site: string
    sectionName: string
    statement: string
}

export type SyndromicCodeType = {
    id: number
    statement: string
}

export type Record = {
    id: string
    time: string
    userTime: string
    errorCode: number
    errorCodeString: ErrorCodeString
    overall: number | null
    type: RecordType
    reviewed: boolean
    duration: number
    deviceId: string
    deviceName: string
    conclusion: number | null
    comments: string | null
    recordingReason: string | null
}

export type RecordSummaryType = Record & {
    patient: Patient
    main: RecordSummaryMain
    signals: EntityByIdMap<RecordSignalType>
    ecgParameters: EntityByIdMap<RecordECGParametersType>
    hrvParameters: RecordHRVParametersType
    amplitudes: EntityByIdMap<RecordAmplitudeType>
    frequency: RecordFrequencyType
    minnesotaCodes: Array<MinnesotaCodeType>
    syndromicCodes: Array<SyndromicCodeType>
    rawSignals?: Array<RecordSignalType>
    histogramChartData?: HistogramChartData
    availableLeads?: Array<RecordSignalType["lead"]>
    details?: SingleRecordDetails
    rhythmEvents: Array<RhythmEventsType>
}

export type GroupedRecords = {
    [key: string]: Array<RecordSummaryType>
}
