import {AppState} from "../../../../store/models"
import {SinglePatientStateType} from "../../models"
import {Record} from "../../../records/models"

// Constants
import {StoreKeys} from "../../../../store/constants"

// Selectors
import {selectRecordsMap} from "../../../records/store/selectors"

const getSinglePatientStateSlice = (state: AppState): SinglePatientStateType => state[StoreKeys.SINGLE_PATIENT];

/**
 * @param state
 */
export const selectCurrentPatientId = (state: AppState) => getSinglePatientStateSlice(state).id;

/**
 * @param state
 */
export const selectActiveRecordId = (state: AppState) => getSinglePatientStateSlice(state).activeRecordId;

/**
 * @param state
 */
export const selectRecordsCurrentPage = (state: AppState) => getSinglePatientStateSlice(state).recordsPage;

/**
 * @param state
 */
export const selectRecordsForPatientFetching = (state: AppState) => getSinglePatientStateSlice(state).recordsFetching;

/**
 * @param state
 */
export const selectMoreRecordsFetching = (state: AppState) => getSinglePatientStateSlice(state).moreRecordsFetching;

/**
 * @param state
 */
export const selectPatientRecordsPage = (state: AppState) => getSinglePatientStateSlice(state).recordsPage;

/**
 * @param state
 */
export const selectPatientRecordsTotalPages = (state: AppState) => getSinglePatientStateSlice(state).recordsTotalPages;

/**
 * @param state
 */
export const selectRecordsForCurrentPatient = (state: AppState): Array<Record> => {
    const {id, recordsIds} = getSinglePatientStateSlice(state);

    if (id === null) return []

    const recordsById = selectRecordsMap(state);

    return recordsIds.map(id => recordsById[id])
};
