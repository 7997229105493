import {RecordSignalType, RecordSummaryType} from "../../records/models"

// Constants
import {DefaultAvailableLeads} from "../constants"
import Theme from "../../../theme/Theme"
import {Patient} from "../../patients/models"
import Utils from "../../../services/Utils/Utils"

/**
 * @param summary
 */
export const extractAvailableLeadsFromSummary = (summary: RecordSummaryType): Array<RecordSignalType["lead"]> => {
    if (summary === null) return DefaultAvailableLeads

    const {signals} = summary;

    if (!signals) {
        return DefaultAvailableLeads
    }

    const leads = Object.keys(signals);

    if (leads.length === 0) {
        return DefaultAvailableLeads
    }

    if (leads.length === 1) {
        return leads
    }

    const IGroup = leads.filter(l => l.match(/^I/g) !== null).sort();
    const AVGroup = leads.filter(l => l.match(/^I/g) === null);
    const other = leads.filter(l => l.match(/(^I|^AV)/g) === null).sort();
    const sortedAVGroup = [];

    if (AVGroup.indexOf("AVR") !== -1) {
        sortedAVGroup.push("AVR");
    }

    if (AVGroup.indexOf("AVL") !== -1) {
        sortedAVGroup.push("AVL");
    }

    if (AVGroup.indexOf("AVF") !== -1) {
        sortedAVGroup.push("AVF");
    }

    return [
        ...IGroup,
        ...sortedAVGroup,
        ...other,
    ]
};

export const getDefaultOpened = arr => arr.reduce((prev, item) => {
    if (typeof item === "undefined") {
        return prev
    }

    if (typeof item.children !== "undefined") {
        return [...prev, ...getDefaultOpened(item.children)]
    }

    return [...prev, item.id]
}, [])

export const getColorForValueRange = (value: number, ranges: Array<Array<number>>) => {
    const [yellow, orange, red] = ranges;

    if (value < red[0]) {
        return Theme.color.red;
    } else if (value >= orange[0] && value < orange[1]) {
        return Theme.color.orange;
    } else if (value >= yellow[0] && value < yellow[1]) {
        return Theme.color.yellow;
    } else {
        return Theme.color.green;
    }
};

export const getColorForValueRanges = (value: number, ranges: Array<Array<number>>) => {
    const [green, yellow, orange] = ranges;

    if ((value >= orange[0] && value < orange[1]) || (value >= orange[2] && value < orange[3])) {
        return Theme.color.orange;
    } else if ((value >= yellow[0] && value < yellow[1]) || (value >= yellow[2] && value < yellow[3])) {
        return Theme.color.yellow;
    } else if (value >= green[0] && value < green[1]) {
        return Theme.color.green;
    } else {
        return Theme.color.red;
    }
};

export const getColorForValueRangeInverted = (value: number, ranges: Array<Array<number>>) => {
    const [green, yellow, orange] = ranges;

    if (value < green[0]) {
        return Theme.color.green;
    } else if (value >= yellow[0] && value < yellow[1]) {
        return Theme.color.yellow;
    } else if (value >= orange[0] && value < orange[1]) {
        return Theme.color.orange;
    } else {
        return Theme.color.red;
    }
};

export const getStaminaHeartRateColorByValue = (rate: number) => {
    if ((rate >= 40 && rate < 50) || (rate >= 120 && rate < 150)) {
        return Theme.color.orange;
    } else if ((rate >= 50 && rate < 60) || (rate >= 100 && rate < 120)) {
        return Theme.color.yellow;
    } else if (rate >= 60 && rate < 100) {
        return Theme.color.green;
    } else {
        return Theme.color.red;
    }
};

export const getStaminaSDNNColorByValue = (value: number) => {
    if (value < 18) {
        return Theme.color.red;
    } else if (value >= 18 && value < 24) {
        return Theme.color.orange;
    } else if (value >= 24 && value < 38) {
        return Theme.color.yellow;
    } else {
        return Theme.color.green;
    }
};

export const getStaminaRMSSDColorByValue = (value: number) => {
    if (value < 9) {
        return Theme.color.red;
    } else if (value >= 9 && value < 19) {
        return Theme.color.orange;
    } else if (value >= 19 && value < 29) {
        return Theme.color.yellow;
    } else {
        return Theme.color.green;
    }
};

export const getStaminaTriangularIndexColorByValue = (value: number) => {
    if (value < 6.33) {
        return Theme.color.red;
    } else if (value >= 6.33 && value < 7.66) {
        return Theme.color.orange;
    } else if (value >= 7.66 && value < 8.99) {
        return Theme.color.yellow;
    } else {
        return Theme.color.green;
    }
};

export const getStaminaPNN50ColorByValue = (value: number) => {
    if (value < 3.5) {
        return Theme.color.red;
    } else if (value >= 3.5 && value < 6.5) {
        return Theme.color.orange;
    } else if (value >= 6.5 && value < 8.5) {
        return Theme.color.yellow;
    } else {
        return Theme.color.green;
    }
};

export const getSignsOfHeartFailure = (result: number) => {
    if (result < 0.15) {
        return {
            color: Theme.color.green,
            value: "common:single_record.very_low"
        };
    } else if (result < 0.22 && result >= 0.15) {
        return {
            color: Theme.color.yellow,
            value: "common:single_record.low"
        };
    } else if (result < 0.26 && result >= 0.22) {
        return {
            color: Theme.color.orange,
            value: "common:single_record.medium"
        };
    } else {
        return {
            color: Theme.color.red,
            value: "common:single_record.high"
        };
    }
};

export const getPsychicStress = (result: number) => {
    if (result < 1.5) {
        return {
            color: Theme.color.green,
            value: "common:details_values.psychic_stress.normal"
        };
    } else if (result >= 1.5 && result < 4.5) {
        return {
            color: Theme.color.yellow,
            value: "common:details_values.psychic_stress.slight_stress"
        };
    } else if (result >= 4.5 && result < 6.5) {
        return {
            color: Theme.color.orange,
            value: "common:details_values.psychic_stress.neuro_emotional_arousal"
        };
    } else {
        return {
            color: Theme.color.red,
            value: "common:details_values.psychic_stress.hr_dysregulation"
        };
    }
};

export const getEmotionalIndex = (result: number) => {
    if (result > 0.75) {
        return {
            color: Theme.color.green,
            value: "common:single_record.normal"
        };
    } else if (result <= 0.75 && result > 0.5) {
        return {
            color: Theme.color.yellow,
            value: "common:single_record.slightly_reduced"
        };
    } else if (result <= 0.5 && result > 0.25) {
        return {
            color: Theme.color.orange,
            value: "common:single_record.moderately_reduced"
        };
    } else {
        return {
            color: Theme.color.red,
            value: "common:single_record.significantly_reduced"
        };
    }
};

export const getFatigueColorAndLevel = (result: number) => {
    if (0 <= result && result <= 1.5) {
        return {
            color: Theme.color.green,
            value: "common:details_values.fatigue.fully_alert"
        };
    } else if (1.5 <= result && result <= 2.5) {
        return {
            color: Theme.color.green,
            value: "common:details_values.fatigue.very_lively"
        };
    } else if (2.5 <= result && result <= 3.5) {
        return {
            color: Theme.color.green,
            value: "common:details_values.fatigue.okay"
        };
    } else if (3.5 <= result && result <= 4.5) {
        return {
            color: Theme.color.yellow,
            value: "common:details_values.fatigue.little_tired"
        };
    } else if (4.5 <= result && result <= 5.5) {
        return {
            color: Theme.color.yellow,
            value: "common:details_values.fatigue.moderately_tired"
        };
    } else if (5.5 <= result && result <= 6.5) {
        return {
            color: Theme.color.orange,
            value: "common:details_values.fatigue.extremely_tired"
        };
    } else if (6.5 <= result && result <= 7.5) {
        return {
            color: Theme.color.red,
            value: "common:details_values.fatigue.completely_exhausted"
        };
    }
};

export const getColorByOverall = (colors: { [key: string]: string }, overall: number) => {
    const {red, orange, green, yellow} = colors;

    if (overall >= 75) {
        return green
    } else if (overall >= 50) {
        return yellow
    } else if (overall >= 25) {
        return orange
    } else {
        return red
    }
};

export const getColorByConfidenceIndex = (colors: { [key: string]: string }, confidence: number) => {
    const {red, orange, green, yellow} = colors;

    if (confidence >= 95) {
        return green
    } else if (confidence >= 90) {
        return yellow
    } else if (confidence >= 75) {
        return orange
    } else {
        return red
    }
};

export const getColorBySignalQualityIndex = (colors: { [key: string]: string }, signalQuality: number) => {
    const {red, orange, green} = colors;

    if (signalQuality > 0.8) {
        return green
    } else if (signalQuality > 0.5) {
        return orange
    } else {
        return red
    }
};

export const convertDuration = (duration: number) => {
    const minutes: number | string = Math.floor(duration / 60);
    const seconds: number | string = duration - minutes * 60;

    return `${minutes} min ${seconds} sec`;
};

export const getEmotionalState = (result: number | string) => {
    if (result === "Normal" || (result > 75 && result <= 100)) {
        return {
            emotionalColor: Theme.color.green,
            emotionalValue: "common:single_record.normal",
        };
    } else if (result === "Mild disorder" || (result > 50 && result <= 75)) {
        return {
            emotionalColor: Theme.color.yellow,
            emotionalValue: "common:single_record.slightly_reduced",
        };
    } else if (result === "Moderate disorder" || (result > 25 && result <= 50)) {
        return {
            emotionalColor: Theme.color.orange,
            emotionalValue: "common:single_record.moderately_reduced",
        };
    } else {
        return {
            emotionalColor: Theme.color.red,
            emotionalValue: "common:single_record.significantly_reduced",
        };
    }
};

export const getHeartRiskColorAndStatus = (result: number | string) => {
    if (result === "Very low" || (result >= 75)) {
        return {
            heartRiskColor: Theme.color.green,
            heartRiskValue: "common:single_record.very_low",
        };
    } else if (result === "Low" || (result < 75 && result >= 50)) {
        return {
            heartRiskColor: Theme.color.yellow,
            heartRiskValue: "common:single_record.low",
        };
    } else if (result === "Medium" || (result < 50 && result >= 25)) {
        return {
            heartRiskColor: Theme.color.orange,
            heartRiskValue: "common:single_record.medium",
        };
    } else {
        return {
            heartRiskColor: Theme.color.red,
            heartRiskValue: "common:single_record.high",
        };
    }
};

export const getArrhythmiaColorAndStatus = (value: number | string) => {
    if (value === "Significant disorder" || (value >= 0 && value < 25)) {
        return {
            arrhythmiaStatus: "common:single_record.significant_disorder",
            arrhythmiaColor: Theme.color.red,
        };
    } else if (value === "Mild disorder" || (value >= 25 && value < 50)) {
        return {
            arrhythmiaStatus: "common:single_record.mild_disorder",
            arrhythmiaColor: Theme.color.orange,
        };
    } else if (value === "Moderate disorder" || (value >= 50 && value < 75)) {
        return {
            arrhythmiaStatus: "common:single_record.moderate_disorder",
            arrhythmiaColor: Theme.color.yellow,
        };
    } else {
        return {
            arrhythmiaStatus: "common:single_record.absent",
            arrhythmiaColor: Theme.color.green,
        };
    }
};

export const getStressColorAndValue = (result: number | string) => {
    if (result === "Normal" || (result >= 0 && result < 120)) {
        return {
            stressColor: Theme.color.green,
            stressValue: "common:single_record.normal",
        };
    } else if (result === "Mild disorder" || (result >= 120 && result < 250)) {
        return {
            stressColor: Theme.color.yellow,
            stressValue: "common:single_record.slightly_increased",
        };
    } else if (result === "Moderate disorder" || (result >= 250 && result < 400)) {
        return {
            stressColor: Theme.color.yellow,
            stressValue: "common:single_record.moderately_increased",
        };
    } else if (result === "Significant disorder" || (result >= 400 && result < 800)) {
        return {
            stressColor: Theme.color.orange,
            stressValue: "common:single_record.significantly_increased",
        };
    } else {
        return {
            stressColor: Theme.color.red,
            stressValue: "common:single_record.dramatically_increased",
        };
    }
};

export const getColorForRecordResult = (result: number): string => {
    if (result >= 75) {
        return Theme.color.green;
    } else if (result >= 50) {
        return Theme.color.yellow;
    } else if (result >= 25) {
        return Theme.color.orange;
    } else {
        return Theme.color.red;
    }
};

export const getFilenameFromDispositionHeader = (disposition: string) => {
   
    const [, filename] = disposition.split("; filename=");

    return filename;
};


export const getFilenameByPatientAndRecordTime = (patient: Patient, time: string, disposition: string) => {
    const date = Utils.formatDate("dd_MM_yyyy", new Date(time).toString());
    const [, format] = disposition.split(".");
    let name = "";

    if (patient === null) {
        name = "unknown"
    } else {
        name = `${patient.firstname} ${patient.lastname}`
            .split(" ")
            .filter(i => !!i)
            .map(Utils.capitalize)
            .join("_");
    }

    return `${name}_${date}.${format.replace('"', '')}`;
};
