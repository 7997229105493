export const DefaultLead = "I";
export const DefaultAvailableLeads = [DefaultLead];

export const RecordSummaryPlates = {
    OVERALL: "OVERALL",
    MYOCARDIUM: "MYOCARDIUM",
    EMOTIONAL: "EMOTIONAL",
    HEART_RISK: "HEART_RISK",
    ARRHYTHMIA: "ARRHYTHMIA",
    STAMINA: "STAMINA",
    HEART_AGE: "HEART_AGE",
    STRESS: "STRESS",
}