import ThemeInterface from "./ThemeInterface";

export default {
    color: {
        white: "#fff",
        black: "#000",
        gray: "#596980",
        veryLightGray: "#ebebeb",
        darkGray: "#313158",
        main: "#313257",
        blue: "#567cf6",
        alternative: "#1c1d3e",
        light: "rgba(216,226,255,0.89)",
        red: "#fa5465",
        orange: "#ffa26d",
        green: "#5ee6b0",
        yellow: "#ffc805",
        border: "#f5f6f7",
        background: "#fafafa",
        sidebarBg: "#feffff",
        tableRowBg: "#f7f7f7"
    },
    shadow: {
        balanced: "0 5px 30px 0 rgba(36, 23, 228, 0.1)",
        default: "0 20px 30px 0 rgba(36, 23, 228, 0.1)",
    },
    fontWeight: {
        regular: 400,
        semiBold: 600,
        bold: 700,
    },
    constants: {
        headerHeight: 70,
        sidebarWidth: 375,
    },
    breakpoints: {
        xs: '480px',
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
        xxl: '1600px',
    }
} as ThemeInterface
