import {AppState} from "../../../../store/models"
import {GroupedRecords, RecordsStateInterface} from "../../models"

// Constants
import {StoreKeys} from "../../../../store/constants"

// Selectors
import {getPatientsStateSlice} from "../../../patients/store/selectors"

/**
 * @param state
 */
export const getRecordsStateSlice = (state: AppState): RecordsStateInterface => state[StoreKeys.RECORDS];

/**
 * @param state
 */
export const selectRecordsMap = (state: AppState) => getRecordsStateSlice(state).recordsById;

/**
 * @param {AppState} state
 */
export const selectSearchParams = (state: AppState) => {
    const {
        size,
        currentPage,
    } = getRecordsStateSlice(state);

    return {
        size,
        page: currentPage,
    }
};

/**
 * @param state
 */
export const selectRecordsList = (state: AppState) => {
    const {recordsById, recordsIds} = getRecordsStateSlice(state);

    return recordsIds.map(id => recordsById[id])
};

/**
 * @param state
 */
export const selectRecordsGroupedByDate = (state: AppState): GroupedRecords => {
    const records = selectRecordsList(state);
    const {patientsById} = getPatientsStateSlice(state);

    return records.reduce((curr, next) => {
        const formattedTime = new Intl.DateTimeFormat("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
        }).format(new Date(next.time));
        const record = {...next};

        // @ts-ignore
        if (next.patient) {
            // @ts-ignore
            record.patient = patientsById[next.patient];
        }

        if (typeof curr[formattedTime] === "undefined") {
            curr[formattedTime] = [record];

            return curr
        }

        return {
            ...curr,
            [formattedTime]: [
                ...curr[formattedTime],
                record,
            ],
        }
    }, {});
};

/**
 * @param state
 */
export const selectMoreRecordsFetching = (state: AppState) => getRecordsStateSlice(state).moreFetching;

/**
 * @param state
 */
export const selectRecordsPage = (state: AppState) => getRecordsStateSlice(state).currentPage;

/**
 * @param state
 */
export const selectRecordsTotalPages = (state: AppState) => getRecordsStateSlice(state).totalPages;
