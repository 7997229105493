import {createAsyncAction, createAction} from "typesafe-actions"

// Helpers
import {idPayload, pagePayload} from "../../../../store/helpers"

// Models
import {Record} from "../../../records/models"
import {Patient} from "../../../patients/models"

// Fetch by id
export const fetchByIdAsync = createAsyncAction(
    "@@single-patient/FETCH_BY_ID",
    "@@single-patient/FETCH_BY_ID_SUCCESS",
    "@@single-patient/FETCH_BY_ID_FAILURE",
)<Patient["id"], Patient, unknown>();

// Fetch patient's records
export const fetchRecordsAsync = createAsyncAction(
    "@@single-patient/FETCH_RECORDS",
    "@@single-patient/FETCH_RECORDS_SUCCESS",
    "@@single-patient/FETCH_RECORDS_FAILURE",
)<Patient["id"], Array<Record["id"]>, unknown>();

// More records
export const fetchRecordsNextPageAsync = createAsyncAction(
    "@@single-patient/FETCH_RECORDS_NEXT_PAGE",
    "@@single-patient/FETCH_RECORDS_NEXT_PAGE_SUCCESS",
    "@@single-patient/FETCH_RECORDS_NEXT_PAGE_FAILURE",
)<[undefined, undefined], Array<Record["id"]>, unknown>();

// Pagination
export const setRecordsTotal = createAction("@@single-patient/SET_CSET_RECORDS_TOTALURRENT",
    (totalElements: number, totalPages: number) => ({totalElements, totalPages}))();
export const setRecordsPage = createAction("@@single-patient/SET_RECORDS_PAGE", pagePayload)();

// Set
export const setCurrentPatientId = createAction("@@single-patient/SET_CURRENT", idPayload)();

// Active record
export const setActiveRecord = createAction("@@single-patient/SET_ACTIVE_RECORD", idPayload)();
