import {Group} from "../../models"
import {EntityByIdMap} from "../../../../models"
import {AppState} from "../../../../store/models"

// Constants
import {StoreKeys} from "../../../../store/constants"

// Selectors
import {getPatientsStateSlice} from "../../../patients/store/selectors"

export const getGroupsStateSlice = (state: AppState) => state[StoreKeys.GROUPS];

export const selectGroupsMap = (state: AppState): EntityByIdMap<Group> => getGroupsStateSlice(state).groupsById

export const selectGroupsList = (state: AppState) => {
    const {patientsById} = getPatientsStateSlice(state);
    const {groupsById, groupsIds} = getGroupsStateSlice(state);

    return groupsIds.map(id => {

        if (typeof groupsById[id].patients === "undefined") {
            return {
                ...groupsById[id],
                patients: [],
            }
        }

        return {
            ...groupsById[id],
            patients: groupsById[id].patients.map(id => patientsById[id]),
        }
    })
};

export const selectGroupsFetching = (state: AppState) => getGroupsStateSlice(state).groupsFetching

export const selectGroupsSearchQuery = (state: AppState) => getGroupsStateSlice(state).query

export const selectGroupsSearching = (state: AppState) => getGroupsStateSlice(state).searching

export const selectGroupCreateState = (state: AppState) => {
    const {groupCreating, groupCreated} = getGroupsStateSlice(state)

    return {
        groupCreated,
        groupCreating,
    }
}