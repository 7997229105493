import {createAsyncAction, createAction} from "typesafe-actions"

// Models
import {CountryPhoneCodeType} from "../../models"

export const loadCountryCodes = createAsyncAction(
    "@@common/LOAD_COUNTRY_CODES",
    "@@common/COUNTRY_CODES_LOAD_SUCCESS",
    "@@common/COUNTRY_CODES_LOAD_FAILURE",
)<[undefined, undefined], { [key: string]: CountryPhoneCodeType }, unknown>();

export const toggleSidebar = createAction("@@common/TOGGLE_SIDEBAR")();
export const toggleSettingsModal = createAction("@@common/TOGGLE_SETTINGS_MODAL")();
