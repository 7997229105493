// Helpers
import {
    getColorForRecordResult,
    getEmotionalState,
    getHeartRiskColorAndStatus,
    getStressColorAndValue
} from "./index"
import {
    getColorForValueRange,
    getColorForValueRangeInverted,
    getColorForValueRanges,
    getEmotionalIndex,
    getFatigueColorAndLevel,
    getPsychicStress,
    getSignsOfHeartFailure,
    getStaminaHeartRateColorByValue,
    getStaminaPNN50ColorByValue,
    getStaminaRMSSDColorByValue,
    getStaminaSDNNColorByValue,
    getStaminaTriangularIndexColorByValue,
} from "./index"

// Models
import { DetailsValue } from "../../records/models"

export default (values: Array<DetailsValue>) => {
    const valuesToObject = (array: Array<DetailsValue>) =>
        array.reduce((parameters: any, item: any) => {
           
            const { normalizedValue, parameterName, lead } = item;
            const nValue = (typeof normalizedValue === "undefined" || normalizedValue === null) ? null : Number(normalizedValue);
            const id = (typeof lead === "undefined" || lead === null) ? parameterName : `${parameterName}_LEAD_${lead}`;

            parameters[id] = {
                id: id,
                value: Number(nValue),
                normalizedValue: nValue,
            };

            return parameters;
        }, {});
    const preparedResult = valuesToObject(values);

    return [
        preparedResult.OVERALL && {
            id: preparedResult.OVERALL.id,
            value: {
                color: getColorForRecordResult(preparedResult.OVERALL.value),
                unit: "%",
                value: preparedResult.OVERALL.value,
            },
            children: [
                preparedResult.STAMINA && {
                    id: preparedResult.STAMINA.id,
                    value: {
                        color: getColorForRecordResult(preparedResult.STAMINA.value),
                        unit: "%",
                        value: preparedResult.STAMINA.value,
                    },
                    children: [
                        preparedResult.IMMEDIATE_CONTROL_OF_REGULATION && {
                            id: preparedResult.IMMEDIATE_CONTROL_OF_REGULATION.id,
                            value: {
                                color: getColorForRecordResult(preparedResult.IMMEDIATE_CONTROL_OF_REGULATION.value),
                                value: preparedResult.IMMEDIATE_CONTROL_OF_REGULATION.value,
                                unit: "%",
                            },
                            children: [
                                preparedResult.HEART_RATE && {
                                    id: preparedResult.HEART_RATE.id,
                                    value: {
                                        unit: "bpm",
                                        value: preparedResult.HEART_RATE.value,
                                        color: getStaminaHeartRateColorByValue(preparedResult.HEART_RATE.value),
                                    },
                                },
                                preparedResult.SDNN && {
                                    id: preparedResult.SDNN.id,
                                    value: {
                                        unit: "ms",
                                        value: preparedResult.SDNN.value,
                                        color: getStaminaSDNNColorByValue(preparedResult.SDNN.value),
                                    },
                                },
                                preparedResult.RMSSD && {
                                    id: preparedResult.RMSSD.id,
                                    value: {
                                        unit: "ms",
                                        value: preparedResult.RMSSD.value,
                                        color: getStaminaRMSSDColorByValue(preparedResult.RMSSD.value),
                                    },
                                },
                                preparedResult.STRESS_INDEX && {
                                    id: preparedResult.STRESS_INDEX.id,
                                    value: {
                                        value: preparedResult.STRESS_INDEX.value,
                                        color: getStressColorAndValue(preparedResult.STRESS_INDEX.value).stressColor,
                                    },
                                },
                                preparedResult.TRIANGULAR_INDEX && {
                                    id: preparedResult.TRIANGULAR_INDEX.id,
                                    value: {
                                        value: preparedResult.TRIANGULAR_INDEX.value,
                                        color: getStaminaTriangularIndexColorByValue(preparedResult.TRIANGULAR_INDEX.value),
                                    },
                                },
                                preparedResult.PNN50 && {
                                    id: preparedResult.PNN50.id,
                                    value: {
                                        unit: "%",
                                        value: preparedResult.PNN50.value,
                                        color: getStaminaPNN50ColorByValue(preparedResult.PNN50.value),
                                    },
                                },
                            ],
                        },
                        preparedResult.PNN20 && {
                            id: preparedResult.PNN20.id,
                            value: {
                                value: preparedResult.PNN20.value,
                            },
                        },
                        preparedResult.SDSD && {
                            id: preparedResult.SDSD.id,
                            value: {
                                value: preparedResult.SDSD.value,
                            },
                        },
                        preparedResult.CONDITION_OF_REGULATION_RESERVES && {
                            id: preparedResult.CONDITION_OF_REGULATION_RESERVES.id,
                            value: {
                                color: getColorForRecordResult(preparedResult.CONDITION_OF_REGULATION_RESERVES.value),
                                value: preparedResult.CONDITION_OF_REGULATION_RESERVES.value,
                                unit: "%",
                            },
                            children: [
                                preparedResult.LF_HF && {
                                    id: preparedResult.LF_HF.id,
                                    value: {
                                        color: getColorForValueRanges(preparedResult.LF_HF.value, [
                                            [1, 3],
                                            [0.75, 1, 3, 3.25],
                                            [0.5, 0.75, 3.25, 3.5],
                                        ]),
                                        value: preparedResult.LF_HF.value,
                                    },
                                },
                                preparedResult.IAE && {
                                    id: preparedResult.IAE.id,
                                    value: {
                                        color: getColorForValueRanges(preparedResult.IAE.value, [
                                            [100, 350],
                                            [60, 110, 350, 700],
                                            [30, 60, 700, 1300],
                                        ]),
                                        value: preparedResult.IAE.value,
                                    },
                                },
                                preparedResult.TOTAL_POWER && {
                                    id: preparedResult.TOTAL_POWER.id,
                                    value: {
                                        color: getColorForValueRanges(preparedResult.TOTAL_POWER.value, [
                                            [1500, 3000],
                                            [700, 1500, 3000, 4000],
                                            [300, 700, 4000, 6000],
                                        ]),
                                        value: preparedResult.TOTAL_POWER.value,
                                    },
                                },
                                preparedResult.ACTIVITY_OF_VASOMOTOR_CENTERS && {
                                    id: preparedResult.ACTIVITY_OF_VASOMOTOR_CENTERS.id,
                                    value: {
                                        color: getColorForValueRangeInverted(preparedResult.ACTIVITY_OF_VASOMOTOR_CENTERS.value, [
                                            [37],
                                            [37, 48],
                                            [48, 62],
                                        ]),
                                        value: preparedResult.ACTIVITY_OF_VASOMOTOR_CENTERS.value,
                                    },
                                },
                                preparedResult.ACTIVITY_OF_SUBCORTICAL_CENTERS && {
                                    id: preparedResult.ACTIVITY_OF_SUBCORTICAL_CENTERS.id,
                                    value: {
                                        color: getColorForValueRanges(preparedResult.ACTIVITY_OF_SUBCORTICAL_CENTERS.value, [
                                            [2.5, 3.5],
                                            [1.5, 2.5, 3.5, 4.5],
                                            [0, 1.5, 4.5, Infinity],
                                        ]),
                                        value: preparedResult.ACTIVITY_OF_SUBCORTICAL_CENTERS.value,
                                    },
                                },
                                preparedResult.ENTROPY && {
                                    id: preparedResult.ENTROPY.id,
                                    value: {
                                        color: getColorForValueRanges(preparedResult.ENTROPY.value, [
                                            [0.3, 0.8],
                                            [0.25, 0.3, 0.8, Infinity],
                                            [0.2, 0.25, 0.8, Infinity],
                                        ]),
                                        value: preparedResult.ENTROPY.value,
                                    },
                                },
                                preparedResult.FRACTAL_INDEX && {
                                    id: preparedResult.FRACTAL_INDEX.id,
                                    value: {
                                        color: getColorForValueRange(preparedResult.FRACTAL_INDEX.value, [
                                            [0.3],
                                            [0.3, 0.5],
                                            [0.5, 0.7],
                                        ]),
                                        value: preparedResult.FRACTAL_INDEX.value,
                                    },
                                },
                            ],
                        },
                        preparedResult.DFA && {
                            id: preparedResult.DFA.id,
                            value: {
                                value: preparedResult.DFA.value,
                            },
                        },
                        preparedResult.VLF && {
                            id: preparedResult.VLF.id,
                            value: {
                                value: preparedResult.VLF.value,
                            },
                        },
                        preparedResult.LF && {
                            id: preparedResult.LF.id,
                            value: {
                                value: preparedResult.LF.value,
                            },
                        },
                        preparedResult.HF && {
                            id: preparedResult.HF.id,
                            value: {
                                value: preparedResult.HF.value,
                            },
                        },
                        preparedResult.LFn && {
                            id: preparedResult.LFn.id,
                            value: {
                                value: preparedResult.LFn.value,
                            },
                        },
                        preparedResult.HFn && {
                            id: preparedResult.HFn.id,
                            value: {
                                value: preparedResult.HFn.value,
                            },
                        },
                        preparedResult.IARS && {
                            id: preparedResult.IARS.id,
                            value: {
                                color: getColorForValueRangeInverted(preparedResult.IARS.value, [
                                    [2.5],
                                    [2.5, 4.5],
                                    [4.5, 6.5],
                                ]),
                                value: preparedResult.IARS.value,
                            },
                        },
                    ],
                },
                preparedResult.MYOCARDIUM && {
                    id: preparedResult.MYOCARDIUM.id,
                    value: {
                        color: getColorForRecordResult(preparedResult.MYOCARDIUM.value),
                        unit: "%",
                        value: preparedResult.MYOCARDIUM.value,
                    },
                    children: [
                        preparedResult.IMMEDIATE_CONTROL_OF_MYOCARDIUM && {
                            id: preparedResult.IMMEDIATE_CONTROL_OF_MYOCARDIUM.id,
                            value: {
                                color: getColorForRecordResult(preparedResult.IMMEDIATE_CONTROL_OF_MYOCARDIUM.value),
                                unit: "%",
                                value: preparedResult.IMMEDIATE_CONTROL_OF_MYOCARDIUM.value,
                            },
                            children: [
                                preparedResult.ST_T_FORM_INDICATOR_LEAD_I && {
                                    id: preparedResult.ST_T_FORM_INDICATOR_LEAD_I.id,
                                    value: {
                                        color: getColorForRecordResult(preparedResult.ST_T_FORM_INDICATOR_LEAD_I.value),
                                        unit: "%",
                                        value: preparedResult.ST_T_FORM_INDICATOR_LEAD_I.value,
                                    },
                                    children: [
                                        preparedResult.ST_DISLOCATION_LEAD_I && {
                                            id: preparedResult.ST_DISLOCATION_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRanges(preparedResult.ST_DISLOCATION_LEAD_I.value, [
                                                    [-0.7, 0.7],
                                                    [-1, -0.7, 0.7, 1],
                                                    [-1.3, -1, 1, 1.3],
                                                ]),
                                                unit: "mV",
                                                value: preparedResult.ST_DISLOCATION_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.T_AMP_NORMALIZED_LEAD_I && {
                                            id: preparedResult.T_AMP_NORMALIZED_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRanges(preparedResult.T_AMP_NORMALIZED_LEAD_I.value, [
                                                    [0.2, 0.8],
                                                    [0.125, 0.2, 0.8, 1.1],
                                                    [0.1, 0.125, 1.1, 1.3],
                                                ]),
                                                unit: "R",
                                                value: preparedResult.T_AMP_NORMALIZED_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.T_SYMMETRY_DERIVATIVES_LEAD_I && {
                                            id: preparedResult.T_SYMMETRY_DERIVATIVES_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRanges(preparedResult.T_SYMMETRY_DERIVATIVES_LEAD_I.value, [
                                                    [0.45, 0.7],
                                                    [0.35, 0.45, 0.7, 0.85],
                                                    [0.3, 0.35, 0.85, 1],
                                                ]),
                                                value: preparedResult.T_SYMMETRY_DERIVATIVES_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.T_SYMMETRY_AREAS_OF_TRIANGLES_LEAD_I && {
                                            id: preparedResult.T_SYMMETRY_AREAS_OF_TRIANGLES_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRanges(preparedResult.T_SYMMETRY_AREAS_OF_TRIANGLES_LEAD_I.value, [
                                                    [1.42, 2.2],
                                                    [1.18, 1.42, 2.2, 2.85],
                                                    [1, 1.18, 2.85, 3.3],
                                                ]),
                                                value: preparedResult.T_SYMMETRY_AREAS_OF_TRIANGLES_LEAD_I.value,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        preparedResult.CONDITION_OF_MYOCARDIUM_RESERVES && {
                            id: preparedResult.CONDITION_OF_MYOCARDIUM_RESERVES.id,
                            value: {
                                color: getColorForRecordResult(preparedResult.CONDITION_OF_MYOCARDIUM_RESERVES.value),
                                unit: "%",
                                value: preparedResult.CONDITION_OF_MYOCARDIUM_RESERVES.value,
                            },
                            children: [
                                preparedResult.AMP_AREAS_INDEX_LEAD_I && {
                                    id: preparedResult.AMP_AREAS_INDEX_LEAD_I.id,
                                    value: {
                                        color: getColorForRecordResult(preparedResult.AMP_AREAS_INDEX_LEAD_I.value),
                                        unit: "%",
                                        value: preparedResult.AMP_AREAS_INDEX_LEAD_I.value,
                                    },
                                    children: [
                                        preparedResult.P_AMP_LEAD_I && {
                                            id: preparedResult.P_AMP_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRangeInverted(preparedResult.P_AMP_LEAD_I.value, [
                                                    [125],
                                                    [125, 150],
                                                    [150, 175],
                                                ]),
                                                unit: "uV",
                                                value: preparedResult.P_AMP_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.Q_AMP_LEAD_I && {
                                            id: preparedResult.Q_AMP_LEAD_I.id,
                                            value: {
                                                unit: "uV",
                                                value: preparedResult.Q_AMP_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.R_AMP_LEAD_I && {
                                            id: preparedResult.R_AMP_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRanges(preparedResult.R_AMP_LEAD_I.value, [
                                                    [650, 900],
                                                    [520, 650, 900, 1200],
                                                    [450, 520, 1200, 1800],
                                                ]),
                                                unit: "uV",
                                                value: preparedResult.R_AMP_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.S_AMP_LEAD_I && {
                                            id: preparedResult.S_AMP_LEAD_I.id,
                                            value: {
                                                unit: "uV",
                                                value: preparedResult.S_AMP_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.T_AMP_LEAD_I && {
                                            id: preparedResult.T_AMP_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRanges(preparedResult.T_AMP_LEAD_I.value, [
                                                    [225, 350],
                                                    [190, 225, 350, 425],
                                                    [140, 190, 425, 500],
                                                ]),
                                                unit: "uV",
                                                value: preparedResult.T_AMP_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.P_AREA_LEAD_I && {
                                            id: preparedResult.P_AREA_LEAD_I.id,
                                            value: {
                                                unit: "uV*s",
                                                value: preparedResult.P_AREA_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.QRS_AREA_LEAD_I && {
                                            id: preparedResult.QRS_AREA_LEAD_I.id,
                                            value: {
                                                unit: "uV*s",
                                                value: preparedResult.QRS_AREA_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.T_AREA_LEAD_I && {
                                            id: preparedResult.T_AREA_LEAD_I.id,
                                            value: {
                                                unit: "uV*s",
                                                value: preparedResult.T_AREA_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.ST_AREA_LEAD_I && {
                                            id: preparedResult.ST_AREA_LEAD_I.id,
                                            value: {
                                                unit: "uV*s",
                                                value: preparedResult.ST_AREA_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.P_QRS_AREA_RATIO_LEAD_I && {
                                            id: preparedResult.P_QRS_AREA_RATIO_LEAD_I.id,
                                            value: {
                                                value: preparedResult.P_QRS_AREA_RATIO_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.QRS_T_AREA_RATIO_LEAD_I && {
                                            id: preparedResult.QRS_T_AREA_RATIO_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRanges(preparedResult.QRS_T_AREA_RATIO_LEAD_I.value, [
                                                    [1, 2],
                                                    [0.8, 1, 2, 8],
                                                    [0.6, 0.8, 8, 15],
                                                ]),
                                                value: preparedResult.QRS_T_AREA_RATIO_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.R_P_RATIO_LEAD_I && {
                                            id: preparedResult.R_P_RATIO_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRange(preparedResult.R_P_RATIO_LEAD_I.value, [
                                                    [6, 7],
                                                    [5, 6],
                                                    [5],
                                                ]),
                                                value: preparedResult.R_P_RATIO_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.Q_R_RATIO_LEAD_I && {
                                            id: preparedResult.Q_R_RATIO_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRangeInverted(preparedResult.Q_R_RATIO_LEAD_I.value, [
                                                    [0.2],
                                                    [0.2, 0.27],
                                                    [0.27, 0.3],
                                                ]),
                                                value: preparedResult.Q_R_RATIO_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.R_T_RATIO_LEAD_I && {
                                            id: preparedResult.R_T_RATIO_LEAD_I.id,
                                            value: {
                                                value: preparedResult.R_T_RATIO_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.R_S_RATIO_LEAD_I && {
                                            id: preparedResult.R_S_RATIO_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRange(preparedResult.R_S_RATIO_LEAD_I.value, [
                                                    [1.8, 2.5],
                                                    [1.2, 1.8],
                                                    [1.2],
                                                ]),
                                                value: preparedResult.R_S_RATIO_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.J_AMP_LEAD_I && {
                                            id: preparedResult.J_AMP_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRangeInverted(preparedResult.J_AMP_LEAD_I.value, [
                                                    [500],
                                                    [500, 1100],
                                                    [1100, 1300],
                                                ]),
                                                value: preparedResult.J_AMP_LEAD_I.value,
                                            },
                                        },
                                    ],
                                },
                                preparedResult.INDEX_OF_ECG_INTERVALS_DURATION && {
                                    id: preparedResult.INDEX_OF_ECG_INTERVALS_DURATION.id,
                                    value: {
                                        color: getColorForRecordResult(preparedResult.INDEX_OF_ECG_INTERVALS_DURATION.value),
                                        unit: "%",
                                        value: preparedResult.INDEX_OF_ECG_INTERVALS_DURATION.value,
                                    },
                                    children: [
                                        preparedResult.P_DUR_LEAD_I && {
                                            id: preparedResult.P_DUR_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRangeInverted(preparedResult.P_DUR_LEAD_I.value, [
                                                    [0.11],
                                                    [0.11, 0.12],
                                                    [0.12, 0.13],
                                                ]),
                                                unit: "s",
                                                value: preparedResult.P_DUR_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.Q_DUR_LEAD_I && {
                                            id: preparedResult.Q_DUR_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRangeInverted(preparedResult.Q_DUR_LEAD_I.value, [
                                                    [0.03],
                                                    [0.03, 0.035],
                                                    [0.035, 0.045],
                                                ]),
                                                unit: "s",
                                                value: preparedResult.Q_DUR_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.S_DUR_LEAD_I && {
                                            id: preparedResult.S_DUR_LEAD_I.id,
                                            value: {
                                                color: getColorForValueRangeInverted(preparedResult.S_DUR_LEAD_I.value, [
                                                    [0.03],
                                                    [0.03, 0.04],
                                                    [0.04, 0.05],
                                                ]),
                                                unit: "s",
                                                value: preparedResult.S_DUR_LEAD_I.value,
                                            },
                                        },
                                        preparedResult.GLOBAL_PR_INT && {
                                            id: preparedResult.GLOBAL_PR_INT.id,
                                            value: {
                                                color: getColorForValueRanges(preparedResult.GLOBAL_PR_INT.value, [
                                                    [0.12, 0.23],
                                                    [0.1, 0.12, 0.23, 0.24],
                                                    [0.09, 0.1, 0.24, 0.26],
                                                ]),
                                                unit: "s",
                                                value: preparedResult.GLOBAL_PR_INT.value,
                                            },
                                        },
                                        preparedResult.GLOBAL_QRS_DUR && {
                                            id: preparedResult.GLOBAL_QRS_DUR.id,
                                            value: {
                                                color: getColorForValueRangeInverted(preparedResult.GLOBAL_QRS_DUR.value, [
                                                    [0.12],
                                                    [0.12, 0.14],
                                                    [0.14, Infinity],
                                                ]),
                                                unit: "s",
                                                value: preparedResult.GLOBAL_QRS_DUR.value,
                                            },
                                        },
                                        preparedResult.GLOBAL_QT_INT && {
                                            id: preparedResult.GLOBAL_QT_INT.id,
                                            value: {
                                                unit: "s",
                                                value: preparedResult.GLOBAL_QT_INT.value,
                                            },
                                        },
                                        preparedResult.GLOBAL_QTc_INT && {
                                            id: preparedResult.GLOBAL_QTc_INT.id,
                                            value: {
                                                color: getColorForValueRanges(preparedResult.GLOBAL_QTc_INT.value, [
                                                    [0.37, 0.46],
                                                    [0.35, 0.37, 0.46, 0.48],
                                                    [0.33, 0.35, 0.48, 0.5],
                                                ]),
                                                unit: "s",
                                                value: preparedResult.GLOBAL_QTc_INT.value,
                                            },
                                        },
                                        preparedResult.GLOBAL_QTcF_INT && {
                                            id: preparedResult.GLOBAL_QTcF_INT.id,
                                            value: {
                                                color: getColorForValueRanges(preparedResult.GLOBAL_QTcF_INT.value, [
                                                    [0.37, 0.46],
                                                    [0.35, 0.37, 0.46, 0.48],
                                                    [0.33, 0.35, 0.48, 0.5],
                                                ]),
                                                unit: "s",
                                                value: preparedResult.GLOBAL_QTcF_INT.value,
                                            },
                                        },
                                        preparedResult.TA_TEND_DUR && {
                                            id: preparedResult.TA_TEND_DUR.id,
                                            value: {
                                                color: getColorForValueRangeInverted(preparedResult.TA_TEND_DUR.value, [
                                                    [0.088],
                                                    [0.088, 0.093],
                                                    [0.093, 0.098],
                                                ]),
                                                unit: "s",
                                                value: preparedResult.TA_TEND_DUR.value,
                                            },
                                        },
                                        preparedResult.MACRUZ_INDEX && {
                                            id: preparedResult.MACRUZ_INDEX.id,
                                            value: {
                                                color: getColorForValueRanges(preparedResult.MACRUZ_INDEX.value, [
                                                    [1.1, 1.6],
                                                    [0.9, 1.1, 1.6, 1.75],
                                                    [0.8, 0.9, 1.75, 1.9],
                                                ]),
                                                value: preparedResult.MACRUZ_INDEX.value,
                                            },
                                        },
                                        preparedResult.DURATION_RATIO_TP_TE_JTA && {
                                            id: preparedResult.DURATION_RATIO_TP_TE_JTA.id,
                                            value: {
                                                color: getColorForValueRange(preparedResult.DURATION_RATIO_TP_TE_JTA.value, [
                                                    [0.47, 0.53],
                                                    [0.42, 0.47],
                                                    [0.42],
                                                ]),
                                                value: preparedResult.DURATION_RATIO_TP_TE_JTA.value,
                                            },
                                        },
                                        preparedResult.DURATION_RATIO_TP_TE_JT && {
                                            id: preparedResult.DURATION_RATIO_TP_TE_JT.id,
                                            value: {
                                                color: getColorForValueRange(preparedResult.DURATION_RATIO_TP_TE_JT.value, [
                                                    [0.27, 0.33],
                                                    [0.22, 0.27],
                                                    [0.22],
                                                ]),
                                                value: preparedResult.DURATION_RATIO_TP_TE_JT.value,
                                            },
                                        },
                                        preparedResult.DURATION_RATIO_JTA_JT && {
                                            id: preparedResult.DURATION_RATIO_JTA_JT.id,
                                            value: {
                                                color: getColorForValueRangeInverted(preparedResult.DURATION_RATIO_JTA_JT.value, [
                                                    [0.73],
                                                    [0.73, 0.88],
                                                    [0.88, 1.3],
                                                ]),
                                                value: preparedResult.DURATION_RATIO_JTA_JT.value,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        preparedResult.SIGN_OF_HEART_FAILURE && {
                            id: preparedResult.SIGN_OF_HEART_FAILURE.id,
                            value: {
                                ...getSignsOfHeartFailure(preparedResult.SIGN_OF_HEART_FAILURE.value),
                            },
                        },
                    ],
                },
                preparedResult.HEART_RHYTHM_DISTURBANCES && {
                    id: preparedResult.HEART_RHYTHM_DISTURBANCES.id,
                    value: {
                        color: getHeartRiskColorAndStatus(preparedResult.HEART_RHYTHM_DISTURBANCES.value).heartRiskColor,
                        value: getHeartRiskColorAndStatus(preparedResult.HEART_RHYTHM_DISTURBANCES.value).heartRiskValue,
                    },
                },
                preparedResult.PSYCHO_EMOTIONAL_STATE && {
                    id: preparedResult.PSYCHO_EMOTIONAL_STATE.id,
                    value: {
                        color: getEmotionalState(preparedResult.PSYCHO_EMOTIONAL_STATE.value).emotionalColor,
                        value: getEmotionalState(preparedResult.PSYCHO_EMOTIONAL_STATE.value).emotionalValue,
                    },
                    children: [
                        preparedResult.PSYCHIC_STRESS && {
                            id: preparedResult.PSYCHIC_STRESS.id,
                            value: {
                                ...getPsychicStress(preparedResult.PSYCHIC_STRESS.value),
                            },
                        },
                        preparedResult.EMOTIONAL_INDEX && {
                            id: preparedResult.EMOTIONAL_INDEX.id,
                            value: {
                                ...getEmotionalIndex(preparedResult.EMOTIONAL_INDEX.value),
                            },
                        },
                    ],
                },
            ],
        },
      
        preparedResult.RISK_OF_HEART_DISORDERS && {
            id: preparedResult.RISK_OF_HEART_DISORDERS.id,
            value: {
                color: getHeartRiskColorAndStatus(preparedResult.RISK_OF_HEART_DISORDERS.value).heartRiskColor,
                value: getHeartRiskColorAndStatus(preparedResult.RISK_OF_HEART_DISORDERS.value).heartRiskValue
            },
        },
        preparedResult.FATIGUE && {
            id: preparedResult.FATIGUE.id,
            value: {
                ...getFatigueColorAndLevel(preparedResult.FATIGUE.value)
            },
        },
    ];
};