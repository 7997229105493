import {createAsyncAction, createAction} from "typesafe-actions"

// Models
import {
    HistogramChartData,
    Record,
    RecordSignalType,
    RecordSummaryType,
    SingleRecordDetails
} from "../../../records/models"

// Summary
export const fetchSummaryByIdAsync = createAsyncAction(
    "@@single-record/FETCH_SUMMARY_BY_ID",
    "@@single-record/FETCH_SUMMARY_BY_ID_SUCCESS",
    "@@single-record/FETCH_SUMMARY_BY_ID_FAILURE",
)<Record["id"], RecordSummaryType, unknown>();

// Raw signal
export const fetchRawSignalByIdAsync = createAsyncAction(
    "@@single-record/FETCH_RAW_SIGNAL_BY_ID",
    "@@single-record/FETCH_RAW_SIGNAL_BY_ID_SUCCESS",
    "@@single-record/FETCH_RAW_SIGNAL_BY_ID_FAILURE",
)<Record["id"], { id: Record["id"], rawSignals: Array<RecordSignalType> }, unknown>();

// Raw file
export const fetchRawFileAsync = createAsyncAction(
    "@@single-record/DOWNLOAD_RAW_FILE",
    "@@single-record/DOWNLOAD_RAW_FILE_SUCCESS",
    "@@single-record/DOWNLOAD_RAW_FILE_FAILURE",
)<Record["id"], [undefined, undefined], unknown>();

export const setRawFile = createAction("@@single-record/SET_RAW_FILE", (blob: Blob, filename: string) => ({ blob, filename }))();

// Excel file
export const fetchExcelFileAsync = createAsyncAction(
    "@@single-record/DOWNLOAD_EXCEL_FILE",
    "@@single-record/DOWNLOAD_EXCEL_FILE_SUCCESS",
    "@@single-record/DOWNLOAD_EXCEL_FILE_FAILURE",
)<Record["id"], [undefined, undefined], unknown>();

export const setExcelFile = createAction("@@single-record/SET_EXCEL_FILE", (blob: Blob, filename: string) => ({ blob, filename }))();

// Excel file
export const fetchPdfFileAsync = createAsyncAction(
    "@@single-record/DOWNLOAD_PDF_FILE",
    "@@single-record/DOWNLOAD_PDF_FILE_SUCCESS",
    "@@single-record/DOWNLOAD_PDF_FILE_FAILURE",
)<Record["id"], [undefined, undefined], unknown>();

export const setPdfFile = createAction("@@single-record/SET_PDF_FILE", (blob: Blob) => ({ blob }))();
// Review
export const markAsReviewed = createAsyncAction(
    "@@single-record/MARK_AS_REVIEWED",
    "@@single-record/MARK_AS_REVIEWED_SUCCESS",
    "@@single-record/MARK_AS_REVIEWED_FAILURE",
)<Record["id"], Record["id"], unknown>();

// Histogram chart data
export const fetchHistogramChartDataAsync = createAsyncAction(
    "@@single-record/FETCH_HISTOGRAM_CHART_DATA",
    "@@single-record/FETCH_HISTOGRAM_CHART_DATA_SUCCESS",
    "@@single-record/FETCH_HISTOGRAM_CHART_DATA_FAILURE",
)<Record["id"], { id: Record["id"], data: HistogramChartData }, unknown>();

// Details report
export const fetchDetailsAsync = createAsyncAction(
    "@@single-record/FETCH_DETAILS",
    "@@single-record/FETCH_DETAILS_SUCCESS",
    "@@single-record/FETCH_DETAILS_FAILURE",
)<Record["id"], { id: Record["id"], data: SingleRecordDetails }, unknown>();
export const toggleDetailsModal = createAction("@@single-record/TOGGLE_DETAILS_MODAL")();

// Leads
export const selectLead = createAction("@@single-record/SELECT_LEAD", (lead: RecordSignalType["lead"]) => ({lead}))();
export const setAvailableLeads = createAction("@@single-record/SET_AVAILABLE", (leads: Array<RecordSignalType["lead"]>) => ({leads}))();

